import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Badge",
  code: "Badge"
};
export const _frontmatter = {
  "menuLabel": "Badge",
  "sortOrder": 2.1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Badge`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-badge--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Badge } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The Badge component is a versatile UI element designed to visually represent the status or value of an object or user input. It supports various customization options, allowing you to tailor its appearance to suit different contexts and design needs. The Badge can display text, numbers, or icons, making it adaptable for a wide range of use cases such as notifications, statuses, and counts.`}</p>
    <p>{`The component allows for different shapes (round or square), sizes (extra small to large), and positions (top right, bottom left, etc.), making it flexible for different layouts. You can also overlay a badge on top of other elements or place it relatively beside them. For added functionality, the Badge supports status indicators (e.g., pending, success, error) and can automatically format text and numbers, including abbreviations for large values.`}</p>
    <p>{`Customization is further enhanced with options for background color, foreground color, border color, and icon color. The Badge component also integrates smoothly with the design system's tokens for consistent styling across your application. Additionally, it includes a skeleton variant for loading states, ensuring a seamless user experience even when data is being fetched. This robust component is designed to be a reliable and visually appealing way to convey important information at a glance.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
         Badges have high contrast between the badge text/icon and its background. It uses clear and legible text labels and icons abd provide dynamic visual feedback for status changes (e.g., color changes for different statuses).
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        Badges are visually distinct and accessible in various states (e.g., loading, different statuses). They allow customization of colors, shapes, and sizes to fit different design needs. Badge positioning helps correct alignment with other elements.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        Use intuitive and descriptive labels for different badge statuses and values.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        Ensures compatibility with various browsers and devices. Uses semantic HTML and ARIA roles for the badge component.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      